<template>
  <nav class="app-header-links text-sm not-italic font-medium">
    <ul class="flex">
      <li v-for="(url, text) in links" :key="text">
        <router-link :to="url" class="text-white" data-test="header-link">{{ text }}</router-link>
      </li>
      <li>
        <a class="text-white" data-testid="sae-link" :href="portalSaeHref" target="_blank">
          {{ $t('LAYOUT.HEADER.SAE_PORTAL') }}
        </a>
      </li>
      <li class="flex flex-row">
        <a class="cursor-pointer text-green-300" @click="logOut">
          {{ $t('LAYOUT.HEADER.LOGOUT') }}
        </a>
        <img
          src="/imgs/logout.svg"
          alt="icono logout"
          class="ml-10 cursor-pointer"
          @click="logOut"
        />
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { NavigationRoutes } from '@/shared/constants/navigation.routes'
import HomeUrl from '@/mixins/HomeUrl'

export default {
  name: 'AppHeaderLinks',
  mixins: [HomeUrl],
  computed: {
    ...mapGetters('auth', ['isDc']),
    ...mapState('portal', ['headerMenu']),
    links() {
      const links = {
        [this.$t('MY_PROFILE.MENU')]: '/my-profile',
        ...this.headerMenu,
      }
      if (this.isDc)
        return {
          [this.$t('HOME.MENU')]: this.homeUrl,
          ...links,
        }
      else return links
    },

    portalSaeHref() {
      return process.env.VUE_APP_SAE
    },
  },
  methods: {
    ...mapActions('auth', ['closeSession']),

    async logOut() {
      const isDc = this.isDc

      await this.closeSession()

      let query
      if (process.env.VUE_APP_ENV !== 'PRO') {
        query = { userType: isDc ? 'dc' : 'senior' }
      }

      this.$router.push({
        path: NavigationRoutes.general.auth.path,
        query,
      })
    },
  },
}
</script>

<style lang="scss">
.app-header-links {
  font-family: 'BBVA', Arial, sans-serif;

  li {
    margin-right: 27px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
